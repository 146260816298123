/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { Dialog } from 'vant';
import { unique } from "@/assets/app.js";
export default {
  props: {
    tremListId: {
      default: []
    }
  },
  components: {},
  data() {
    return {
      active: 0,
      showInfo: false,
      ListId: [],
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 10,
      result: [],
      itemString: "",
      wpInfoData: {}
    };
  },
  created() {
    this.getCgwpInfo();
    this.data = this.mount;
  },
  computed: {
    ListIdNew() {
      this.ListId = [];
      this.tremListId.map(item => {
        this.ListId.push(item.pkId);
      });
      return this.ListId; //watch监听要求
    }
  },

  watch: {
    ListIdNew: function (newid) {
      this.$refs.checkboxGroup.toggleAll();
      this.result = newid;
    }
  },
  /*
  我的申请（type前端直接固定：myApply）
  http://47.100.165.67:8084/xzoa/bpm/core/bpmInst/listByTreeId.do
    我的待办（type前端直接固定：myTask）
  http://47.100.165.67:8084/xzoa/bpm/core/bpmTask/myTasks.do
    我的已办（type前端直接固定：myAttend）
  http://47.100.165.67:8084/xzoa/bpm/core/bpmInstCc/byMeJson.do
    我的抄送  (type前端直接固定：myCc）
  http://47.100.165.67:8084/xzoa/bpm/core/bpmInstCc/byMeJson.do
  */
  methods: {
    onClick(name, title) {
      //console.log(name,title);
      console.log(this.active);
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var urlStr = '';
      if (this.active == 0) {
        urlStr = `/bpm/core/bpmInst/listByTreeId.do?pageIndex=${pageNum}&pageSize=${this.pageSize}`;
      } else if (this.active == 1) {
        urlStr = `/bpm/core/bpmTask/myTasks.do?pageIndex=${pageNum}&pageSize=${this.pageSize}`;
        // name description
      } else if (this.active == 2) {
        urlStr = `/oa/personal/bpmInst/myAttendsData.do?pageIndex=${pageNum}&pageSize=${this.pageSize}`;
        //taskNodes   subject
      } else if (this.active == 3) {
        urlStr = `/bpm/core/bpmInstCc/byMeJson.do?pageIndex=${pageNum}&pageSize=${this.pageSize}`;
        //nodeName   subject
      }

      var url = _baseUrl + urlStr; //    `/bpm/core/bpmTask/myTasks.do?pageIndex=${pageNum}&pageSize=${this.pageSize}`;
      this.$ajax.post(url).then(res => {
        this.data = res.data.data;
        // this.data=[];
        // var lsData = res.data.data;
        // for(var i=0;i<lsData.length;i++){
        //     var obj={};
        //     obj.pkid = lsData[i].pkId;
        //     obj.identifyLabel = lsData[i].identifyLabel;
        //     if(this.active == 0){

        //     }else if(this.active == 1){
        //        obj.title = lsData[i].description;
        //        obj.node = lsData[i].name;
        //     }else if(this.active == 2){
        //        obj.title = lsData[i].subject;
        //        obj.node = lsData[i].taskNodes;
        //     }else if(this.active == 3){
        //        obj.title = lsData[i].subject;
        //        obj.node = lsData[i].nodeName;
        //     }
        //     this.data.push(obj);
        // }

        for (var i = 0; i < this.data.length; i++) {
          if (this.active == 0) {
            this.data[i].type = 'myApply';
          } else if (this.active == 1) {
            this.data[i].type = 'myTask';
          } else if (this.active == 2) {
            this.data[i].type = 'myAttend';
          } else if (this.active == 3) {
            this.data[i].type = 'myCc';
          }
        }
        this.data.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.identifyLabel) == -1) {
            this.mount.push(item);
          }
        });
        console.log(this.data, this.mount);
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / this.pageSize);
        console.log(this.mount.length);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      var pageNum = this.pageNum;
      var maxPageNum = this.maxPageNum;
      if (pageNum <= maxPageNum) {
        this.getCgwpInfo();
      } else {
        Dialog({
          message: '请正确输入页数！'
        });
      }
    },
    goodsInfo(item) {
      this.showInfo = true;
      this.wpInfoData = item;
    },
    //确定
    onSelects() {
      let result = [];
      this.mount.map(item => {
        this.result.map(pkid => {
          if (pkid == item.pkId) {
            result.push(item);
          }
        });
      });
      console.log(result);
      this.$emit("cgwpList", result);
    }
  }
};