/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import MyTable from "../../common/Cwggsq/MyTable";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, unique, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqCwggsq",
  components: {
    LCFQheader,
    MyTable
  },
  data() {
    return {
      checked: false,
      oneft: "/index",
      headTit: "新建申请",
      tit: "错误更改申请流程",
      showDataCG: false,
      showDataCG2: false,
      showsyqck: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      resultFa: [],
      tremListId: [],
      cgwpDatas: [],
      //选中的子项目
      wpInfoData: {},
      boDefId: "",
      formKey: "",
      fjsc: [],
      datas: {
        ypbh: '',
        ggsqr: '',
        ggsqr_name: '',
        ggsqrq: '',
        yqggnr: '',
        fj: []
      },
      cgsqrT: new Date(),
      allowSelect: true,
      showUserDialog: false,
      showUserDialog2: false,
      showGroupDialog: false,
      single: true,
      single2: false,
      nextNodeId: ''
    };
  },
  created() {
    this.cgORxj();
    this.initBySolInstId();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      let instId = this.$route.params.instId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId; //+"&instId="+instId
      this.$ajax.post(url).then(response => {
        console.log(response);
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });
      var url2 = _baseUrl + "/bpm/core/bpmInst/startFlowToJson.do?solId=" + solId;
      this.$ajax.post(url2).then(res => {
        console.log(res.data.data);
        var j = JSON.parse(res.data.data);
        console.log(j.nodeId);
        this.nextNodeId = j.nodeId;
      });
      //http://47.100.165.67:8084/xzoa/bpm/core/bpmInst/startFlowToJson.do?solId=1505787417397305346
    },

    async cgORxj() {
      let instId = this.$route.params.instId;
      console.log('instId = ' + instId);
      if (instId != 0) {
        console.log('this.instId!=0....');
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";

        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No; //+"&instId="+instId
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          let ren = [{
            id: resData.ggsqr,
            text: resData.ggsqr_name
          }];
          this_.datas.ggsqr = JSON.stringify(ren);
          this_.datas.ggsqr_name = resData.ggsqr_name;
          this_.datas.ggsqrq = resData.ggsqrq;
          this_.datas.ypbh = resData.ypbh;
          this_.datas.yqggnr = resData.yqggnr;

          //this_.daatas.fj附件因为存入数据和当时原始数据不同，所以拿到的存入数据无法回填。
          console.log(this_.datas);
        });
        /***/
      }
    },

    //获取用户信息
    getUser() {
      //this.datas.sqr = RxUtil.getCache("userName");
    },
    onConfirmCG(timestamp) {
      this.datas.ggsqrq = timesFnt(timestamp, 'T');
      this.cgsqrT = formatHMT(timestamp);
      this.showDataCG = false;
    },
    nodePushData(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      console.log(json);
      this.nodeUserVm.setValue(json);
    },
    selectUser(data) {
      this.nodePushData(data);
      this.showUserDialog = false;
    },
    selectUser2(data) {
      this.nodePushData(data);
      this.showUserDialog2 = false;
    },
    //选代理人
    selectUserDialog(vm) {
      this.datas.ggsqr = '';
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    //选审批人
    selectUserDialog2(vm) {
      this.datas.spr = '';
      this.showUserDialog2 = true;
      this.$refs.sysUser2.search();
      // 设置选择用户。
      this.$refs.sysUser2.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    showList() {
      this.tremListId = this.cgwpDatas;
      this.showListpage = true;
    },
    cgwpList(list) {
      this.cgwpDatas = list;
      console.log('增加了条目=', this.cgwpDatas);
      this.showListpage = false;
    },
    //查看具体信息
    goodsInfo(item) {
      this.showGinfo = true;
      this.wpInfoData = item;
      console.log(item, this.wpInfoData);
    },
    //添加信息
    wpListInfo(item) {
      this.cgwpDatas.map(datai => {
        if (datai.pkId == item.pkId) {
          datai = item;
        }
      });
      this.showGinfo = false;
    },
    //删除
    delectWP() {
      console.log(this.resultFa); //选中的要删的
      console.log(this.cgwpDatas); //现有的
      for (let i = 0; i < this.resultFa.length; i++) {
        for (let y = 0; y < this.cgwpDatas.length; y++) {
          if (this.resultFa[i] == this.cgwpDatas[y].pkId) {
            this.cgwpDatas.splice(y, 1);
          }
        }
      }
      this.$refs.checkboxGroup.toggleAll(false);
    },
    upDatas() {
      console.log(this.datas);
      console.log('进来了');
      if (this.datas.ggsqr == '') {
        this.errorToast("请选择更改申请人", "1800");
        return;
      }
      //****************************************************
      var sqr = JSON.parse(this.datas.ggsqr);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            ypbh: this.datas.ypbh,
            ggsqr: sqr[0].id,
            ggsqr_name: sqr[0].text,
            ggsqrq: this.datas.ggsqrq,
            yqggnr: this.datas.yqggnr,
            ggyqfj: this.fjsc
          }
        }]
      };
      console.log(jsonDataObj);
      var jsonData = JSON.stringify(jsonDataObj);

      //**************************************************
      var spr = JSON.parse(this.datas.spr);
      var sprids = this.arrToIds(spr);
      var spz = JSON.parse(this.datas.spz);
      var spzids = this.arrToIds(spz);
      var destNodeUsers = [{
        nodeId: this.nextNodeId,
        userIds: sprids,
        groupIds: spzids
      }];
      destNodeUsers = JSON.stringify(destNodeUsers);
      //***************************************************
      var relInst = this.cgwpDatasToArr();
      relInst = JSON.stringify(relInst);
      //***************************************************
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("opinion", this.datas.spyj);
      params.append("destNodeUsers", destNodeUsers);
      params.append("relInst", relInst);
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.datas.ypbh) || this.iN(this.datas.ggsqr) || this.iN(this.datas.yqggnr) || this.iN(this.datas.spr) || this.iN(this.datas.spz) || this.iN(this.datas.spyj)) {
        return true;
      }
      return false;
    },
    arrToIds(arr) {
      var ids = '';
      for (var item of arr) {
        if (ids == '') {
          ids = item.id;
        } else {
          ids = ids + "," + item.id;
        }
      }
      return ids;
    },
    cgwpDatasToArr() {
      var arr = [];
      for (var item of this.cgwpDatas) {
        //console.log(item);
        var obj = {};
        obj.instId = item.instId;
        obj.type = item.type;
        obj.subject = item.subject;
        if (item.type == 'myTask') {
          obj.subject = item.description;
        }
        arr.push(obj);
      }
      return arr;
    },
    onSubmit() {
      //console.log(this.datas,this.cgwpDatas);
      //console.log(this.cgwpDatas);

      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.upDatas();
      //return;
      if (params == null) {
        this.errorToast("参数填写不全！", "1800");
        return;
      }
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.upDatas();
      if (params == null) {
        this.errorToast("参数填写不全！", "1800");
        return;
      }
      // return;

      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onLct() {
      console.log(this.actDefId);
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    },
    //上传附件
    afterRead(file) {
      console.log('afterRead', file);
      let formData = new FormData();
      formData.append("from", "SELF");
      formData.append("types", "");
      formData.append("sysTreeId", "");
      formData.append("id", "WU_FILE_0");
      formData.append("name", file.file.name);
      formData.append("type", file.file.type);
      formData.append("lastModifiedDate", file.file.lastModifiedDate);
      formData.append("size", file.file.size);
      formData.append("file", file.file);
      var fjObj;
      let formDataUrl = _baseUrl + "/sys/core/file/upload.do";
      this.$ajax.post(formDataUrl, formData).then(res => {
        fjObj = {};
        fjObj.fileId = res.data.data[0].fileId;
        fjObj.fileName = res.data.data[0].fileName;
        fjObj.totalBytes = res.data.data[0].totalBytes;
        this.fjsc.push(fjObj);
      });
      console.log(this.fjsc, this.datas.fj);
    },
    beforeDelete(file) {
      console.log('beforeDelete', file);
      for (let i = 0; i < this.fjsc.length; i++) {
        if (file.file.name == this.fjsc[i].fileName) {
          this.fjsc.splice(i, 1);
        }
      }
      console.log(this.fjsc, this.datas.fj);
      return true;
    }
  }
};